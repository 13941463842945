<mat-form-field [style.margin-top]="'5px'">
  <mat-label>Välj projekt att lägga till</mat-label>
  <mat-select (selectionChange)="changeSelector($event)" [value]="null"
              ngDefaultControl>
    <mat-option
      *ngFor="let iGitLabProject of filteredGitLabProjects"
      [value]="iGitLabProject.id">{{ iGitLabProject.name }}
    </mat-option>
  </mat-select>
</mat-form-field>
