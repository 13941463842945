import {HttpClient} from '@angular/common/http'
import {Inject, Injectable, signal} from '@angular/core'
import {BehaviorSubject, first, Observable} from 'rxjs'
import {map, switchMap} from 'rxjs/operators'
import {environment} from '../../environments/environment'
import {LOCAL_STORAGE} from '../application/app'
import {GitProject, IGitLabProject} from '../application/data-types'
import {InfoData, ProjectInfo} from './types'

export interface GitLabFile {
  /**
   * The git sha-1 (?) of the file, we do not use it but
   * it is there
   */
  id: string

  /**
   * The file name, we do not use it atm b/c it is part of
   * the path
   */
  name: string

  /**
   * The complete filename including path
   */
  path: string

  /**
   * Type seems to be always "tree" cannot understand
   */
  type: string
}

@Injectable({
  providedIn: 'root'
})
export class DeployService {
  /**
   * Consumers should listen to this to get the latest git projects.
   */
  public gitProjects: BehaviorSubject<GitProject[]> = new BehaviorSubject<GitProject[]>([{} as any])

  public iGitLabProjects$ = signal<IGitLabProject[]>([])

  constructor(
    private httpClient: HttpClient,
    @Inject(LOCAL_STORAGE) private injectedLocalStorage: Storage
  ) {
  }

  /**
   * Start the stage process for a project
   *
   * @param projectId - The id of the project to stage
   * @param level - PATCH | MINOR | MAJOR
   */
  public stageProject(
    projectId: string,
    level: string
  ): Observable<any> {
    const url = `${environment.ssAPIUrl}/deploy/stage`
    const data = {
      accessToken: this.getGitLabToken(),
      projectId,
      level,
      packageFile: ''
    }
    return this.gitProjects.pipe(
      first(),
      switchMap((projects: GitProject[]) => {
        const project = projects.find((p: GitProject) => p.projectKey === projectId) as GitProject
        data.packageFile = project.packageJson
        return this.httpClient.put<any>(url, data)
      })
    )

  }

  public releaseProject(projectId: string, version: string): Observable<any> {
    const url = `${environment.ssAPIUrl}/deploy/release`
    const data = {
      accessToken: this.getGitLabToken(),
      projectId,
      version
    }
    return this.httpClient.put<any>(url, data)
  }

  /**
   * Fetch a list of projects for a group
   *
   * @param accessToken
   * @param groupId
   */
  public getProjectsFromGitLab(accessToken: string, groupId: string): Observable<IGitLabProject[]> {
    const url = `${environment.ssAPIUrl}/deploy/projects`
    const data = {
      accessToken,
      groupId
    }
    return this.httpClient.put<IGitLabProject[]>(url, data)
  }

  public getReleasesForProject(projectId: string): Observable<string[]> {
    const url = `${environment.ssAPIUrl}/deploy/project/releases`
    const data = {
      accessToken: this.getGitLabToken(),
      projectId
    }
    return this.httpClient.put<string[]>(url, data)
  }

  /**
   * get all items from siteGitProjectData database Table
   */
  public getGitProjects(): Observable<GitProject[]> {
    const url = `${environment.ssAPIUrl}/deploy/git-projects`
    return this.httpClient.get<GitProject[]>(url)
      .pipe(
        map((gitProjects: GitProject[]) => {
          this.gitProjects.next(gitProjects)
          return gitProjects
        })
      )
  }

  /**
   * Save a project to database
   */
  public updateGitProjects(project: GitProject): Observable<GitProject[]> {
    const url = `${environment.ssAPIUrl}/deploy/git-projects`
    return this.httpClient.put<GitProject>(url, project)
      .pipe(
        switchMap(() => this.getGitProjects())
      )
  }

  /**
   * Delete a project from database
   */
  public deleteGitProject(id: string | undefined): Observable<void> {
    const url = `${environment.ssAPIUrl}/deploy/git-projects/${id}`
    return this.httpClient.delete<void>(url)
  }

  public getFilesForProject(projectId: string | number): Observable<GitLabFile[]> {
    const url = `${environment.ssAPIUrl}/deploy/projects/${projectId}/files`
    return this.httpClient.get<GitLabFile[]>(url)
  }

  public getVersionForProject(projectId: string, typeOfProject: string): Observable<ProjectInfo> {
    const data: InfoData = {projectId, fileNames: []}

    data.fileNames.push('.gitlab-ci.yml')

    if (typeOfProject === 'backend') {
      data.fileNames.push('infrastructure/common/variables.tf')
      data.fileNames.push('spec/spec.yml')
    }


    if (typeOfProject === 'frontend') {
      data.fileNames.push('infrastructure/common/cloudfront.tf')
      data.fileNames.push('tsconfig.json')
      data.fileNames.push('src/app/common/header/header.component.ts')
      data.fileNames.push('src/app/services/config.service.ts')
      data.fileNames.push('src/app/app.component.html')
    }

    if (typeOfProject === 'frontendWithoutSSO') {
      data.fileNames.push('infrastructure/common/cloudfront.tf')
      data.fileNames.push('tsconfig.json')
      data.fileNames.push('src/app/app.component.html')
    }

    if (typeOfProject === 'layer') {
      data.fileNames.push('layer/nodejs/package.json')
    }

    if (typeOfProject === 'lib') {
      data.fileNames.push('dist/package.json')
    }
    const url = `${environment.ssAPIUrl}/deploy/projects/info`
    return this.httpClient.put<ProjectInfo>(url, data)
  }


  /**
   * For some reason "this" is not "this" ...
   *
   * @param token - The token to set.
   */
  public setGitLabToken = (token: string): void => {
    this.injectedLocalStorage.setItem('gitLabAccessToken', token)
  }

  public getGitLabToken = (): string => this.injectedLocalStorage.getItem('gitLabAccessToken') || ''
}
