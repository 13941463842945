<div class="spb-holder">
  <spb-header></spb-header>
  <div class="spb-plain-content">
    <router-outlet></router-outlet>
  </div>
  <div class="spb-filler"></div>
  <spb-footer
    (darkTheme)="configService.switchTheme($event)"
    (logoutUser)="configService.logout()"
    [license]="true"
    [settingsServiceUrl]="settingsServiceUrl"
    [token]="(configService.accessToken$ | async)"
    [version]="version"
    copyrightYears="2022-2024"
  ></spb-footer>
</div>

