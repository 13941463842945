import {Component, OnInit} from '@angular/core'
import {MatButton} from '@angular/material/button'
import {MatDialog} from '@angular/material/dialog'
import {DeployService} from '../../services/deploy.service'
import {AccessTokenComponent} from '../access-token/access-token.component'
import {GitProjectsComponent} from '../git-projects/git-projects.component'

@Component({
  selector: 'spb-deploy-home',
  templateUrl: './deploy-home.component.html',
  styleUrls: ['./deploy-home.component.scss'],
  imports: [MatButton, GitProjectsComponent]
})
export class DeployHomeComponent implements OnInit {

  constructor(
    private dialog: MatDialog,
    private deployService: DeployService
  ) {
  }

  public ngOnInit(): void {
    if (!this.deployService.getGitLabToken()) {
      this.openAccessToken()
    }
  }

  /**
   * Open Access token dialog
   */
  public openAccessToken() {
    this.dialog.open(AccessTokenComponent)
  }
}
