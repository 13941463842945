import {Component, Inject} from '@angular/core'
import {MatButton} from '@angular/material/button'
import {
  MAT_DIALOG_DATA,
  MatDialogActions,
  MatDialogClose,
  MatDialogContent,
  MatDialogRef,
  MatDialogTitle
} from '@angular/material/dialog'
import {GitProject} from '../../../application/data-types'
import {DeployService} from '../../../services/deploy.service'

@Component({
  selector: 'spb-delete-git-project-dialog',
  templateUrl: './delete-git-project-dialog.component.html',
  imports: [MatDialogTitle, MatDialogContent, MatDialogActions, MatButton, MatDialogClose]
})
export class DeleteGitProjectDialogComponent {

  constructor(private deployService: DeployService,
              public dialogRef: MatDialogRef<DeleteGitProjectDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public project: GitProject) {
  }

  /**
   * Delete DatabaseProject from the table
   */
  public deleteDatabaseProject(): void {
    /**
     * Delete the project using service
     */
    this.deployService.deleteGitProject(this.project.itemId).subscribe()
    this.dialogRef.close(this.project)
  }

}
