import {NgFor} from '@angular/common'
import {Component, EventEmitter, Input, OnChanges, Output} from '@angular/core'
import {ReactiveFormsModule} from '@angular/forms'
import {MatOption} from '@angular/material/core'
import {MatFormField, MatLabel} from '@angular/material/form-field'
import {MatSelect, MatSelectChange} from '@angular/material/select'
import {GitProject, IGitLabProject} from '../../../application/data-types'

@Component({
  selector: 'spb-auto-add-project',
  templateUrl: './auto-add-project.component.html',
  imports: [MatFormField, MatLabel, MatSelect, ReactiveFormsModule, NgFor, MatOption]
})
export class AutoAddProjectComponent implements OnChanges {
  /**
   * all projects from database and from git lab
   */
  @Input() gitDatabaseProjects: GitProject[] = []

  @Input() gitLabProjects: IGitLabProject[] = []

  /**
   * We emit on this when a project is selected
   */
  @Output() gitProject: EventEmitter<IGitLabProject> = new EventEmitter()

  public filteredGitLabProjects: IGitLabProject[] = []

  public ngOnChanges(): void {
    /**
     * create an array of numbers
     */
    const tmpDatabaseNumbers = this.gitDatabaseProjects
      .map((project: GitProject) => (project.projectKey))

    /**
     * Exclude all projects already in the database
     */
    this.filteredGitLabProjects = this.gitLabProjects
      .filter((p: IGitLabProject) => !tmpDatabaseNumbers.includes(p.id.toString()))
  }

  /**
   * change selector for iGitLabProject
   */
  public changeSelector(event: MatSelectChange) {
    const project: IGitLabProject = this.gitLabProjects.find((p: IGitLabProject) => p.id === event.value) as IGitLabProject
    this.gitProject.emit(project)
  }
}
