import {Component, OnInit} from '@angular/core'
import {FormControl, ReactiveFormsModule} from '@angular/forms'
import {MatButton} from '@angular/material/button'
import {MatDialogClose, MatDialogContent} from '@angular/material/dialog'
import {MatFormField, MatLabel} from '@angular/material/form-field'
import {MatInput} from '@angular/material/input'
import {DeployService} from '../../services/deploy.service'

@Component({
  selector: 'spb-access-token',
  templateUrl: './access-token.component.html',
  styleUrls: ['./access-token.component.scss'],
  imports: [MatDialogContent, MatFormField, MatLabel, MatInput, ReactiveFormsModule, MatButton, MatDialogClose]
})
export class AccessTokenComponent implements OnInit {

  public accessTokenCtrl = new FormControl<string>('', {nonNullable: true})

  constructor(
    private deployService: DeployService
  ) {
  }

  ngOnInit(): void {
    this.accessTokenCtrl.setValue(this.deployService.getGitLabToken())
    this.accessTokenCtrl.valueChanges.subscribe(this.deployService.setGitLabToken)
  }
}
