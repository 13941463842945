import {HttpClient} from '@angular/common/http'
import {Injectable} from '@angular/core'
import {BehaviorSubject, Observable} from 'rxjs'
import {map, switchMap} from 'rxjs/operators'
import {environment} from '../../environments/environment'
import {Site} from './types'

@Injectable({
  providedIn: 'root'
})
export class SiteService {
  /**
   * Consumers should listen to this to get the latest user and roles list.
   */
  public sites: BehaviorSubject<Site[]> = new BehaviorSubject<Site[]>([{} as any])

  /**
   * @param httpClient - To gain some https access.
   */
  constructor(
    private httpClient: HttpClient
  ) {
  }

  /**
   * get all sites from database
   */
  public getAllSites(): Observable<Site[]> {
    const url = `${environment.ssAPIUrl}/sites`
    return this.httpClient.get<Site[]>(url)
      .pipe(
        map((sites: Site[]) => {
          this.sites.next(sites)
          return sites
        })
      )
  }

  /**
   * Save a site to database
   */
  public updateSites(site: Site): Observable<Site[]> {
    const url = `${environment.ssAPIUrl}/sites`
    return this.httpClient.put<Site>(url, site)
      .pipe(
        switchMap(() => this.getAllSites())
      )
  }

  /**
   * Delete a site from database
   */
  public deleteSite(id: string): Observable<void> {
    const url = `${environment.ssAPIUrl}/sites/${id}`
    return this.httpClient.delete<void>(url)
  }
}
