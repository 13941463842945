import {Routes} from '@angular/router'
import {DeployHomeComponent} from './deploy-home/deploy-home.component'
import {GitlabComponent} from './gitlab/gitlab.component'

export default [
  {
    path: '',
    component: DeployHomeComponent
  },
  {
    path: 'gitlab',
    component: GitlabComponent
  }
] satisfies Routes

