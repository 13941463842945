import {inject} from '@angular/core'
import {ResolveFn} from '@angular/router'
import {Observable} from 'rxjs'
import {Role} from '../services/types'
import {UserService} from '../services/user.service'


export const roleResolver: ResolveFn<Role[]> = (): Observable<Role[]> => {
  const service = inject(UserService)
  return service.getAllRoles()
}
