<div class="middle-holder">
  <div class="middle">
    <div [routerLink]="['/home']" class="logo">
      Landing App
    </div>
    <div class="spb-filler"></div>

    <div *ngIf="(configService.logInState$ | async) as user" class="logged-in">
      <span>{{ user.user.name }}</span>
      <div *ngIf="user.user.name" class="middle-menu">
        <div>
          <button [matMenuTriggerFor]="menu" mat-button>
            <mat-icon>menu</mat-icon>
          </button>
          <mat-menu #menu="matMenu">
            <button [routerLink]="['/home']" mat-menu-item>Hem</button>

            <button *ngIf="isUserDeveloper" [routerLink]="['/deploy']"
                    mat-menu-item>Deploy
            </button>

            <div *ngIf="configService.logInState$ | async as user">
              <button *ngIf="isUserAdmin" [routerLink]="['/admin']"
                      mat-menu-item>
                Admin
              </button>
            </div>
            <button (click)="openSettings()" mat-menu-item>
              Inställningar
            </button>
            <button (click)="configService.logout()" mat-menu-item>Logga ut
            </button>
          </mat-menu>
        </div>
      </div>
    </div>
  </div>
</div>
